import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";

ReactDOM.render(
  <>
    {
      (window.location.href =
        "https://www.techieey.com/live/bootcamp/fullstack-web-dev")
    }
  </>,
  document.getElementById("root")
);
